<template>
  <section>
    <p v-if="!books.length" class="font-weight-light text-center">
      <slot name="noDataMessage">
        Aucun livre à afficher
      </slot>
    </p>
    <ul v-else class="books-display">
      <li
        v-for="(book, index) in books"
        :key="index"
        @click="showBookDetails(book)"
      >
        <book-display :book="book" />
      </li>
    </ul>

    <book-modal
      v-if="bookSelected"
      :show="showBookSelected"
      :book="bookSelected"
      @close="closeModal"
    />
  </section>
</template>

<script setup>
import BookDisplay from "@/components/book/BookDisplay.vue";
import BookModal from "@/components/book/BookModal.vue";
import { ref } from "vue";

const props = defineProps({
  books: {
    type: Array,
    required: true,
  },
});

/**
 * Affichage de la modale
 */
const showBookSelected = ref(false);
const bookSelected = ref(null);
const showBookDetails = async (book) => {
  bookSelected.value = book;
  showBookSelected.value = true;
  history.pushState({ modalOpen: true }, null);
};

const closeModal = () => {
  showBookSelected.value = false;
  bookSelected.value;
};
</script>

<style scoped>
ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 1vw;
  row-gap: 1vh;
  padding-left: 0;
}
li {
  list-style: none;
}
</style>