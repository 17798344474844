<template>
  <section>
    <div class="stat-display" :style="`background-color: ${color}`">
      <div>
        <img :src="image" class="stat-image" alt="Stat image">
      </div>
      <div>
        <p>{{ Math.round(animatedValue) }}</p>
        <p>{{ title }}</p>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  value: {
    type: Number,
    required: true,
  },
  image: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    default: "var(--main-color)",
  },
});

const animatedValue = ref(0);
watch(() => props.value, (newVal) => {
  const duration = 2000; // Durée de l'animation en millisecondes
  const startTime = performance.now();

  const animate = (currentTime) => {
    const elapsed = currentTime - startTime;
    const progress = Math.min(elapsed / duration, 1);

    // Fonction d'atténuation easeOutCubic
    const easing = (t) => (-- t) * t * t + 1;
    animatedValue.value = easing(progress) * newVal;

    requestAnimationFrame(animate);
  };

  requestAnimationFrame(animate);
}, { immediate: true });
</script>

<style scoped>
.stat-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: var(--main-color-lighter);
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  overflow: hidden;
  position: relative;
}
.stat-image {
  position: absolute;
  top: 0%;
  left: 20%;
  width: 100%;
  opacity: 0.1;
}
</style>