import { createRouter, createWebHistory } from "vue-router";

import AuthenticationDisplay from "./components/authentication/AuthenticationDisplay.vue";
import ImportBooks from "./components/book/ImportBooks";
import MyBooksDisplay from "./components/book/MyBooksDisplay";
import SearchBooks from "./components/book/SearchBooks";
import StatsDisplay from "./components/stat/StatsDisplay";

const routes = [
  {
    path: "/",
    redirect: "/search",
  },
  {
    path: "/login",
    name: "login",
    component: AuthenticationDisplay,
  },
  {
    path: "/search",
    name: "searchBooks",
    component: SearchBooks,
  },
  {
    path: "/mybooks",
    name: "mybooks",
    component: MyBooksDisplay,
  },
  {
    path: "/import",
    name: "importBooks",
    component: ImportBooks,
  },
  {
    path: "/stats",
    name: "stats",
    component: StatsDisplay,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;