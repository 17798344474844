export class Book {

  title;
  publisher;
  authors;
  categories;
  description;
  isbn;
  date;
  nbPages;
  images;

  constructor (data) {
    this.title = data.title;
    this.publisher = data.publisher;
    this.authors = data.authors;
    this.categories = data.categories;
    this.description = data.description;
    this.isbn = data.isbn;
    this.date = data.date;
    this.nbPages = data.nbPages;
    this.images = data.images;
  }

  isUserBook () {
    return false;
  }

}