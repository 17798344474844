<template>
  <h2 class="page-title">
    Mes livres
  </h2>

  <section class="actions">
    <router-link to="/import" class="btn d-flex align-items-center" active-class="active">
      <i class="bi bi-cloud-arrow-up me-2" />
      Importer un fichier goodreads
    </router-link>
    <router-link class="btn d-flex align-items-center" to="/search" active-class="active">
      <i class="bi bi-plus me-2" />
      Ajouter un livre
    </router-link>
  </section>

  <section class="books-list mt-5">
    <books-display :books="books" />
  </section>
</template>

<script setup>
import BooksDisplay from "@/components/book/BooksDisplay.vue";
import { UserBook } from "@/models/UserBook";
import LibraryService from "@/services/LibraryService";
import { ref } from "vue";

const books = ref([]);

(async () => {
  const { data } = await LibraryService.getMyBooks();
  books.value = data.map(item => {
    return new UserBook({
      ...item.book,
      readDate: item.readDate,
      addedDate: item.addedDate,
      userBookId: item.id,
    });
  });
})();
</script>

<style scoped>
.actions {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 2vh;
}
</style>