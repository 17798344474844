<template>
  <div class="input-date-wrapper">
    <i class="bi" :class="icon" />
    <div v-if="!isEditing" style="display: inline; margin-left: 5px;" @click="toggleEdition">
      {{ formattedDate || "-" }}
    </div>
    <input
      v-else
      ref="input"
      v-model="date"
      type="date"
      class="form-control form-control-sm"
      @blur="toggleEdition"
      @keyup.enter="toggleEdition"
    >
  </div>
</template>

<script setup>
import { format } from "date-fns";
import { computed, nextTick, ref } from "vue";

const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
});

/**
 * Gestion de la date en v-model
 */
const model = defineModel({ type: String });
const emit = defineEmits(["update:modelValue"]);

/**
 * Gestion de l'édition de la date
 */
const date = ref(model.value);
const isEditing = ref(false);
const formattedDate = computed(() => {
  if (model.value) {
    return format(new Date(model.value), "dd/MM/yyyy");
  }
  return "";
});

const input = ref(null);
const toggleEdition = async () => {
  isEditing.value = ! isEditing.value;
  if (isEditing.value) {
    if (! date.value) {
      date.value = format(new Date(), "yyyy-MM-dd");
    }
    await nextTick();
    input.value.focus();
  } else {
    emit("update:modelValue", date.value);
  }
};
</script>

<style scoped>
.input-date-wrapper {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 10px;
}
</style>