import { useNotification } from "@kyvg/vue3-notification";

const notification = useNotification();

const notifyError = (message) => {
  notification.notify({
    type: "error",
    title: "Erreur",
    text: message,
  });
};

const notifySuccess = (message) => {
  notification.notify({
    type: "success",
    title: "Succès",
    text: message,
  });
};

export { notifyError, notifySuccess };