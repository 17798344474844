<template>
  <article class="card">
    <div class="row g-0">
      <div class="col-md-4">
        <img :src="cover" class="d-block mx-auto">
      </div>
      <div class="col-md-8">
        <div class="card-header">
          <div class="d-flex justify-content-between w-100">
            <div>
              <div>{{ book.title }}</div>
              <ul>
                <li v-for="author in book.authors" :key="author" class="author">
                  {{ author.name }}
                </li>
              </ul>
              <div v-if="book.publisher">
                {{ book.publisher.name }}
              </div>
            </div>
            <div v-if="props.book.userBookId">
              <date-input v-model="addedDate" icon="bi-bookmark-plus" />
              <date-input v-model="readDate" icon="bi-bookmark-check" />
            </div>
          </div>
          <!-- <country-flag :country="book.language" size="small" />-->
        </div>
        <div class="card-body">
          <span v-for="category in book.categories" :key="category.title" class="badge bg-secondary">{{ category }}</span>
          <p class="card-text">
            {{ book.description }}
          </p>
          <div v-for="a_isbn in book.isbn" :key="a_isbn.identifier" class="text-muted">
            {{ a_isbn.type }} {{ a_isbn.identifier }}
          </div>
        </div>
        <div class="card-footer ">
          <div class="text-muted">
            {{ book.date }}
          </div>
          <div>
            {{ book.nbPages }} pages
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script setup>
import DateInput from "@/components/form/DateInput.vue";
import LibraryService from "@/services/LibraryService";
import { notifyError, notifySuccess } from "@/utils/notification";
import { computed, ref, watch } from "vue";

const props = defineProps({
  book: {
    type: Object,
    required: true,
  },
});

const cover = computed(() => {
  return props.book.images?.cover;
});

/*
  * Gestion de la date d'ajout
*/
const addedDate = ref(props.book.addedDate);
watch(() => props.book, () => {
  addedDate.value = props.book.addedDate;
}, { deep: true });
watch(addedDate, async () => {
  try {
    await LibraryService.updateAddedDate(props.book.userBookId, addedDate.value);
    notifySuccess("Date d'ajout mise à jour.");
  } catch (error) {
    notifyError("Erreur lors de la mise à jour de la date d'ajout à votre bibilothèque.");
  }
});

/*
  * Gestion de la date de lecture
*/
const readDate = ref(props.book.readDate);
watch(() => props.book, () => {
  readDate.value = props.book.readDate;
}, { deep: true });
watch(readDate, async () => {
  try {
    await LibraryService.updateReadDate(props.book.userBookId, readDate.value);
    notifySuccess("Date de lecture mise à jour.");
  } catch (error) {
    notifyError("Erreur lors de la mise à jour de la date de lecture.");
  }
});
</script>

<style scoped>
  li {
    list-style: none;
  }
    ul {
    display: flex;
    padding-left: 0;
    flex-wrap: wrap;
  }
    li.card{
    margin-bottom: 10px;
  }
  .author ~ .author::before{
    content: ", ";
  }
  img {
    padding-top: 10px;
  }
  .card-footer, .card-header{
    display: flex;
    justify-content: space-between;
  }
</style>