<template>
  <section>
    <form
      class="d-flex"
      role="search"
      @submit.prevent="fetchFirstBooks"
    >
      <input
        id="author"
        v-model="searchData.search.author"
        class="form-control"
        type="search"
        placeholder="Auteur"
        name="author"
      >
      <input
        id="author"
        v-model="searchData.search.title"
        class="form-control"
        type="search"
        placeholder="Titre"
        name="title"
      >
      <button class="btn">
        Chercher
      </button>
    </form>

    <section class="results mt-5">
      <books-display :books="books">
        <template #noDataMessage>
          <p v-if="wasLaunched" class="font-weight-light text-center">
            Aucun résultat
          </p>
          <p v-else class="font-weight-light text-center">
            Veuillez lancer une recherche
          </p>
        </template>
      </books-display>
      <book-paging v-if="books" v-model:firstResultIndex="searchData.startIndex" :have-more-results="searchData.haveMoreResults" />
    </section>
  </section>
</template>

<script setup>
import BookPaging from "@/components/book/BookPaging.vue";
import BooksDisplay from "@/components/book/BooksDisplay.vue";
import { Book } from "@/models/Book";
import LibraryService from "@/services/LibraryService";
import { notifyError } from "@/utils/notification";
import { reactive, ref, watch } from "vue";

const searchData = reactive({
  nbResults: 0,
  search: { author: "" },
  startIndex: 0,
  haveMoreResults: false,
});
const wasLaunched = ref(false);
const books = ref([]);

watch(() => searchData.startIndex, () => {
  fetchMoreResults();
});

const fetchBooks = async () => {
  try {
    return await fetchBooksFromApi();
  } catch (error) {
    notifyError("Une erreur s'est produite lors de la recherche.");
  }
};

const fetchBooksFromApi = async () => {
  const response = await LibraryService.find({
    title: searchData.search.title,
    author: searchData.search.author,
    index: searchData.startIndex,
  });

  searchData.haveMoreResults = ! (response.data.items == null || response.data.items.length < 10);
  if(response.data.items !== null) {
    searchData.nbResults = response.data.nbResults;
    return response.data.items.map(item => {
      return new Book(item.book);
    });
  }
  wasLaunched.value = true;
};

const fetchFirstBooks = async () => {
  searchData.startIndex = 0;
  books.value = [];
  const booksFetched = await fetchBooks();
  books.value = booksFetched ? booksFetched : [];
};

const fetchMoreResults = async () => {
  const booksFetched = await fetchBooks();
  books.value.push(...booksFetched);
};
</script>

<style scoped>
form {
    margin-bottom: 10px;
}
.form-control {
    margin-right: 1vw;
}
</style>