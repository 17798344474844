import http from "@/http-common";
import { computed, onBeforeMount, readonly, ref } from "vue";

const token = ref("");

export function useAuthentication () {

  onBeforeMount(() => {
    token.value = localStorage.getItem("jwtToken");
  });

  const isConnected = computed(() => {
    return Boolean(token.value);
  });

  const signIn = async (login, password) => {
    const { data } = await http.post("/login", {
      login,
      password,
    });

    token.value = data.accessToken;
    if (token.value) {
      localStorage.setItem("jwtToken", data.accessToken);
    }
    return data;
  };

  const signOut = async () => {
    await http.post("/logout");
    token.value = "";
    localStorage.removeItem("jwtToken");
  };

  return {
    isConnected: readonly(isConnected),
    signIn,
    signOut,
  };
}