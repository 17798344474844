import { Book } from "./Book.js";

export class UserBook extends Book {

  readDate;
  addedDate;
  userBookId;

  constructor (data) {
    super(data);
    this.readDate = data.readDate;
    this.addedDate = data.addedDate;
    this.userBookId = data.userBookId;
  }

  isUserBook () {
    return true;
  }

}