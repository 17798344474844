<template>
  <section class="main">
    <div class="sidebar d-flex flex-column flex-shrink-0 p-3">
      <a href="/" class="app-logo d-flex align-items-center mb-3 link-body-emphasis text-decoration-none">
        <img
          src="/logos/logo.png"
          alt="MyLibraryStats"
          width="32"
          height="32"
        >
        <span>MyLibraryStats</span>
      </a>
      <nav class="nav nav-underline flex-column justify-content-between" style="height: 100%;">
        <section>
          <router-link
            v-if="isConnected"
            to="/stats"
            class="nav-link"
            active-class="active"
          >
            Mes stats
            <i class="bi bi-graph-up" />
          </router-link>
          <router-link
            v-if="isConnected"
            class="nav-link"
            to="/mybooks"
            active-class="active"
          >
            Mes livres
            <i class="bi bi-book" />
          </router-link>
          <router-link class="nav-link" to="/search" active-class="active">
            Rechercher
            <i class="bi bi-search" />
          </router-link>
        </section>
        <section>
          <authentication-section />
        </section>
      </nav>
    </div>
    <div class="main-content container-fluid p-5 m-2">
      <router-view />
    </div>

    <notifications position="bottom left" />
  </section>
</template>

<script setup>
import { useAuthentication } from "@/use/useAuthentication";
const { isConnected } = useAuthentication();
import AuthenticationSection from "@/components/authentication/AuthenticationSection.vue";

</script>

<style scoped>
	.main {
    display: flex;
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    margin-bottom: 0;
    background-color: var(--main-color);
    background: linear-gradient(to right, var(--main-color), var(--secondary-color));
	}
  .sidebar {
    width: 20vw;
    min-width: 260px;
    height: 100%;
    overflow-y: hidden;
  }
  .app-logo {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    justify-content: center;
  }
  .app-logo img {
    margin-right: 10px;
  }
  .app-logo span {
    font-size: 2em;
    color: var(--bs-white);
  }
  .nav-link {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    color: var(--bs-white);
    text-decoration: none;
  }
  .nav-link.active {
    font-weight: 500;
    color: var(--bs-white);
  }
  .main-content {
    position: relative;
    max-height: 100vh;
    overflow-y: auto;
    border-radius: 10px;
    background-color: var(--bs-white);
  }
</style>

<style>
h2.page-title {
  font-size: 2.2em;
  color: var(--accent-color);
  text-align: left;
}
button.btn {
  background-color: var(--accent-color);
  color: var(--bs-white);
  transition: background-color 0.2s ease-in-out;
}
</style>