<template>
  <div class="form-group stat-filter stat-filter--year">
    <label for="dashboardSelect">Année</label>
    <select id="dashboardSelect" v-model="year" class="form-control">
      <option v-for="oneyear in lastTwoYears" :key="oneyear" :value="oneyear">
        {{ oneyear }}
      </option>
    </select>
  </div>
</template>

<script setup>
const year = defineModel("year", { required: true });
const lastTwoYears = Array.from({ length: 2 }, (_, i) => year.value - i);
</script>

<style scoped>
.stat-filter--year select {
    border: 0;
    width: auto;
    appearance: auto;
    padding: 5px;
}
.stat-filter--year {
    display: flex;
    align-items: center;
    column-gap: 5px;
}
</style>