<template>
  <div
    id="book-display-modal"
    class="modal fade modal-xl"
    tabindex="-1"
    aria-labelledby="bookDisplayModal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="exampleModalLabel" class="modal-title fs-5">
            {{ book?.title }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          />
        </div>
        <div class="modal-body">
          <book-details-display :key="book.id" :book="book" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import BookDetailsDisplay from "@/components/book/BookDetailsDisplay.vue";
import { Modal } from "bootstrap";
import { onMounted, ref, watch } from "vue";

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  book: {
    type: Object,
    required: true,
  },
});
const emits = defineEmits(["close"]);

/**
 * Affichage de la modale
 */
const modal = ref();
onMounted(() => {
  modal.value = Modal.getOrCreateInstance("#book-display-modal");
  const myModalEl = document.getElementById("book-display-modal");
  myModalEl.addEventListener("hidden.bs.modal", () => {
    emits("close");
  });

  if (props.show) {
    modal.value.show();
  }
});

watch(() => props.show, (show) => {
  if (show) {
    modal.value.show();
  } else {
    modal.value.hide();
  }
});

const closeModal = () => {
  emits("close");
};
</script>