import http from "../http-common";

/**  Données qui concernent les statistiques de l'utilisateur connecté **/
class StatsService {

  getTotalPages ({ year }) {
    return http.get("/stats/totalPages", { params: { year } });
  }

  getTotalBooks ({ year }) {
    return http.get("/stats/totalBooks", { params: { year } });
  }

  getTotalAuthors ({ year }) {
    return http.get("/stats/totalAuthors", { params: { year } });
  }

  getTotalPublishers ({ year }) {
    return http.get("/stats/totalPublishers", { params: { year } });
  }

  getMostReadPublisher ({ year }) {
    return http.get("/stats/mostReadPublisher", { params: { year } });
  }

  getMostReadAuthor ({ year }) {
    return http.get("/stats/mostReadAuthor", { params: { year } });
  }

  getNumberOfBooksPerMonth ({ year }) {
    return http.get("/stats/numberOfBooksPerMonth", { params: { year } });
  }

  getNumberOfPagesPerMonth ({ year }) {
    return http.get("/stats/numberOfPagesPerMonth", { params: { year } });
  }

  getShortestBook ({ year }) {
    return http.get("/stats/shortestBook", { params: { year } });
  }

  getLongestBook ({ year }) {
    return http.get("/stats/longestBook", { params: { year } });
  }
}
export default new StatsService();