<template>
  <div class="book-cover-wrapper">
    <div v-if="haveCover" class="book-cover" :style="`background-image:url('${book.images?.cover}')`" />
    <div v-else class="book-cover--no-image">
      <p>{{ book.title }}</p>
    </div>
    <div class="book-cover__actions">
      <slot />
    </div>
    <div class="book-cover-loader" />
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";

const props = defineProps({
  book: {
    type: Object,
    required: true,
  },
});

const haveCover = ref(false);
onMounted(async () => {
  if (! props.book.images?.cover) {
    haveCover.value = false;
    return;
  }

  const img = document.createElement("img");
  img.src = props.book.images?.cover;
  document.querySelector(".book-cover-loader").appendChild(img);
  try {
    await new Promise((resolve) => {
      img.onload = () => {
        const width = img.clientWidth;
        const height = img.clientHeight;
        // OpenLibrary returns a 1x1 image when there is no cover
        haveCover.value = width > 1 && height > 1;
        img.parentNode.removeChild(img);
        resolve();
      };
    });
  } catch (error) {
    haveCover.value = false;
  }
});
</script>

<style scoped>
.book-cover, .book-cover--no-image {
  width: 150px;
  height: 230px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
}
.book-cover--no-image {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e9ecef;
  color: #495057;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
}
.book-cover:hover, .book-cover--no-image:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: transform .1s ease-in-out;
}
.book-cover-wrapper {
  position: relative;
}
.book-cover__actions {
  position: absolute;
  top: 10px;
  right: 10px;
}
.book-cover-loader {
  position: absolute;
  top: 200dvh;
}
</style>