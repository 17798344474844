import http from "../http-common";

class LibraryService {

  /**
   * @param {String} params.title
   * @param {String} params.author
   * @param {Number} params.index
   * @returns
   */
  find (params = {}) {
    return http.get("/books", { params });
  }

  /**  Données qui sont rattachés à l'utilisateur connecté **/

  importBooks (file) {
    const formData = new FormData();
    formData.append("file", file);
    return http.post("/me/books/file", formData, { headers: { "Content-Type": "multipart/form-data" } });
  }

  /**
   * Retourne la liste des livres de l'utilisateur connecté
   * @param {String} params.isbn - ISBN du livre
   * @returns {Promise}
   */
  getMyBooks (params = {}) {
    return http.get("/me/books", { params });
  }

  addBook (book) {
    return http.post("/me/books", book);
  }

  updateAddedDate (id, addedDate) {
    return http.patch(`/me/books/${id}`, { addedDate });
  }

  updateReadDate (id, readDate) {
    return http.patch(`/me/books/${id}`, { readDate });
  }

}

export default new LibraryService();