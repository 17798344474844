import StatsService from "@/services/StatsService";
import { notifyError } from "@/utils/notification";
import { computed, ref, watch } from "vue";

const totalPages = ref(0);
const totalBooks = ref(0);
const totalAuthors = ref(0);
const totalPublishers = ref(0);
const mostReadPublisher = ref("");
const mostReadAuthor = ref("");
const numberOfBooksPerMonth = ref([]);
const numberOfPagesPerMonth = ref([]);
const shortestBook = ref({});
const longestBook = ref({});

/**
* Permet de récupérer l’ensemble des stats sur les livres de l'utilisateur
* @param year l'année en prendre en compte dans la requête des stats
**/
export function useStats (year) {

  const statsYear = computed(() => {
    return year.value;
  });

  const getTotalPages = async () => {
    try{
      const totalPagesResult = await StatsService.getTotalPages({ year: statsYear.value });
      totalPages.value = totalPagesResult.data;
    } catch (error) {
      notifyError("Une erreur s'est produite lors du calcul du nombre de pages.");
    }
  };

  const getTotalBooks = async () => {
    try{
      const totalBooksResult = await StatsService.getTotalBooks({ year: statsYear.value });
      totalBooks.value = totalBooksResult.data;
    } catch (error) {
      notifyError("Une erreur s'est produite lors du calcul du nombre de livres.");
    }
  };

  const getTotalAuthors = async () => {
    try{
      const totalAuthorsResult = await StatsService.getTotalAuthors({ year: statsYear.value });
      totalAuthors.value = totalAuthorsResult.data;
    } catch (error) {
      notifyError("Une erreur s'est produite lors du calcul du nombre d'auteurs lus.");
    }
  };

  const getTotalPublishers = async () => {
    try{
      const totalAuthorsResult = await StatsService.getTotalPublishers({ year: statsYear.value });
      totalPublishers.value = totalAuthorsResult.data;
    } catch (error) {
      notifyError("Une erreur s'est produite lors du calcul du nombre de maisons d'édition lus.");
    }
  };

  const getMostReadPublisher = async () => {
    try{
      const mostReadPublisherResult = await StatsService.getMostReadPublisher({ year: statsYear.value });
      mostReadPublisher.value = mostReadPublisherResult.data;
    } catch (error) {
      notifyError("Une erreur s'est produite lors du calcul de la maison d'édition la plus lue.");
    }
  };

  const getMostReadAuthor = async () => {
    try{
      const mostReadAuthorResult = await StatsService.getMostReadAuthor({ year: statsYear.value });
      mostReadAuthor.value = mostReadAuthorResult.data;
    } catch (error) {
      notifyError("Une erreur s'est produite lors du calcul de l'auteur le plus lu.");
    }
  };

  const getNumberOfBooksPerMonth = async () => {
    try{
      const numberOfBooksPerMonthResult = await StatsService.getNumberOfBooksPerMonth({ year: statsYear.value });
      numberOfBooksPerMonth.value = numberOfBooksPerMonthResult.data;
    } catch (error) {
      notifyError("Une erreur s'est produite lors du calcul du nombre de livres par mois.");
    }
  };

  const getNumberOfPagesPerMonth = async () => {
    try{
      const numberOfPagesPerMonthResult = await StatsService.getNumberOfPagesPerMonth({ year: statsYear.value });
      numberOfPagesPerMonth.value = numberOfPagesPerMonthResult.data;
    } catch (error) {
      notifyError("Une erreur s'est produite lors du calcul du nombre de pages par mois.");
    }
  };

  const getShortestBook = async () => {
    try{
      const shortestBookResult = await StatsService.getShortestBook({ year: statsYear.value });
      shortestBook.value = shortestBookResult.data;
    } catch (error) {
      notifyError("Une erreur s'est produite lors du calcul du livre le plus court.");
    }
  };

  const getLongestBook = async () => {
    try{
      const longestBookResult = await StatsService.getLongestBook({ year: statsYear.value });
      longestBook.value = longestBookResult.data;
    } catch (error) {
      notifyError("Une erreur s'est produite lors du calcul du livre le plus long.");
    }
  };

  watch(statsYear, () => {
    getTotalPages();
    getTotalBooks();
    getTotalAuthors();
    getTotalPublishers();
    getMostReadPublisher();
    getMostReadAuthor();
    getNumberOfBooksPerMonth();
    getNumberOfPagesPerMonth();
    getLongestBook();
    getShortestBook();
  }, { immediate: true });

  return {
    totalPages,
    totalBooks,
    totalAuthors,
    totalPublishers,
    mostReadPublisher,
    mostReadAuthor,
    numberOfBooksPerMonth,
    numberOfPagesPerMonth,
    shortestBook,
    longestBook,
  };

}