import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/style/colors.css";

import Notifications from "@kyvg/vue3-notification";
import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";

createApp(App).use(router).use(Notifications).mount("#MyLibraryStatsApp");