<template>
  <router-link
    v-if="!isConnected"
    class="nav-link flex-shrink-1"
    to="/login"
    active-class="active"
  >
    Se connecter
    <i class="bi bi-box-arrow-in-right" />
  </router-link>
  <button
    v-else
    class="nav-link flex-shrink-1"
    style="width: 100%;"
    @click="logout"
  >
    Se déconnecter
    <i class="bi bi-box-arrow-left" />
  </button>
</template>

<script setup>
import router from "@/router";
import { useAuthentication } from "@/use/useAuthentication";
import { notifyError, notifySuccess } from "@/utils/notification";

const { isConnected, signOut } = useAuthentication();

/**
 * Déconnexion
 */
const logout = async () => {
  try {
    await signOut();
    notifySuccess("Vous êtes déconnecté.");
    await router.push("/search");
  } catch (error) {
    notifyError("Une erreur s'est produite lors de la déconnexion.");
  }
};
</script>