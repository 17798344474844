<template>
  <section class="stats-dashboard">
    <h2 class="page-title">
      Mes stats
    </h2>
    <header class="filters">
      <filters-list v-model:year="year" />
    </header>
    <main class="container-fluid mt-3">
      <header class="row g-3">
        <number-simple-stat
          class="col"
          title="Livres"
          :value="totalBooks"
          :image="booksStatImage"
          color="#72aadb80"
        />
        <number-simple-stat
          class="col"
          title="Pages"
          :value="totalPages"
          :image="pagesStatImage"
          color="#dd496d80"
        />
        <number-simple-stat
          class="col"
          title="Auteurs"
          :value="totalAuthors"
          :image="authorsStatImage"
          color="#ffc10780"
        />
        <number-simple-stat
          class="col"
          title="Maisons d'édition"
          :value="totalPublishers"
          :image="publisherStatImage"
          color="#47d55280"
        />
      </header>
    </main>
    <main class="container-fluid mt-3">
      <section class="grid gx-3">
        <div class="grid-sizer col-xs-12 col-sm-6 col-lg-4" />
        <div class="grid-item col-xs-12 col-sm-8 col-lg-8">
          <books-per-month
            class="grid-item-content m-2"
            :number-of-books="numberOfBooksPerMonth"
            :number-of-pages="numberOfPagesPerMonth"
          />
        </div>
        <div class="grid-item col-xs-12 col-sm-4 col-lg-4">
          <text-simple-stat
            class="grid-item-content m-2"
            title="Auteur/Autrice le plus lu"
            :value="mostReadAuthor"
            :image="authorsStatImage"
            color="#ffc10780"
          />
        </div>
        <div class="grid-item col-xs-12 col-sm-4 col-lg-4">
          <text-simple-stat
            class="grid-item-content m-2"
            title="Maison d'édition la plus lue"
            :value="mostReadPublisher"
            :image="publisherStatImage"
            color="#47d55280"
          />
        </div>
        <div class="grid-item col-xs-12 col-sm-6 col-lg-4">
          <shortest-longest-book-stat
            class="grid-item-content m-2"
            :longuest-book="longestBook"
            :shortest-book="shortestBook"
          />
        </div>
      </section>
    </main>
  </section>
</template>

<script setup>
import authorsStatImage from "@/assets/images/stat-author.svg";
import booksStatImage from "@/assets/images/stat-books.svg";
import pagesStatImage from "@/assets/images/stat-pages.svg";
import publisherStatImage from "@/assets/images/stat-publisher.svg";
import FiltersList from "@/components/filters/FiltersList.vue";
import BooksPerMonth from "@/components/stat/BooksPerMonth.vue";
import NumberSimpleStat from "@/components/stat/NumberSimpleStat.vue";
import ShortestLongestBookStat from "@/components/stat/ShortestLongestBookStat.vue";
import TextSimpleStat from "@/components/stat/TextSimpleStat.vue";
import { useStats } from "@/use/useStats";
import Masonry from "masonry-layout";
import { onMounted, onUpdated, ref } from "vue";

/**
 * Gestion des filtres
 **/
const year = ref(new Date().getFullYear());

/**
 * Gestion des statistiques
 **/
const { totalPages, totalBooks, totalAuthors, totalPublishers, mostReadPublisher, mostReadAuthor, numberOfBooksPerMonth, numberOfPagesPerMonth, longestBook, shortestBook } = useStats(year);

/**
 * Gestion du mansory
 */
onMounted(() => {
  new Masonry(".grid", {
    itemSelector: ".grid-item", // use a separate class for itemSelector, other than .col-
    columnWidth: ".grid-sizer",
    percentPosition: true,
  });
});
onUpdated(() => {
  new Masonry(".grid", {
    itemSelector: ".grid-item", // use a separate class for itemSelector, other than .col-
    columnWidth: ".grid-sizer",
    percentPosition: true,
  });
});
</script>