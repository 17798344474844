<template>
  <div>
    <div class="container d-flex">
      <input
        id="formFile"
        class="form-control"
        type="file"
        @change="uploadFile"
      >
      <button class="btn" @click="submitFile">
        Importer
      </button>
    </div>
  </div>

  <div class="mt-5">
    <p v-if="books.length">
      Nombre de livres importés : {{ books.length }}
      <a
        data-bs-toggle="collapse"
        href="#importedBooks"
        role="button"
        aria-expanded="false"
        aria-controls="importedBooks"
      >Détails</a>
    </p>
    <div id="importedBooks" class="collapse">
      <books-display :books="books" />
    </div>
    <p v-if="rejectedbooks.length">
      Nombre de livres rejetés : {{ rejectedbooks.length }}
      <a
        data-bs-toggle="collapse"
        href="#rejectedBooks"
        role="button"
        aria-expanded="false"
        aria-controls="rejectedBooks"
      >Détails</a>
    </p>
    <div id="rejectedBooks" class="collapse">
      <books-display :books="rejectedbooks" />
    </div>
  </div>
</template>

<script setup>

import BooksDisplay from "@/components/book/BooksDisplay.vue";
import { Book } from "@/models/Book";
import { UserBook } from "@/models/UserBook";
import LibraryService from "@/services/LibraryService";
import { notifyError } from "@/utils/notification";
import { ref } from "vue";

const books = ref([]);
const rejectedbooks = ref([]);
const file = ref(null);
const uploadFile = (event) => {
  file.value = event.target.files[0];

};

const submitFile = async () => {
  try{
    const { data } = await LibraryService.importBooks(file.value);
    books.value = data.importedBooks.map(item => {
      return new UserBook({
        ...item.book,
        readDate: item.readDate,
        addedDate: item.addedDate,
        userBookId: item.id,
      });
    });
    rejectedbooks.value = data.rejectedBooks.map(item => {
      return new Book(item);
    });
  } catch (error) {
    notifyError("Une erreur s'est produite lors de l'importation du fichier.");
  }
};

</script>