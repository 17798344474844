<template>
  <book-cover :book="book">
    <template v-if="isConnected">
      <div v-if="isLoading" class="spinner-border loading" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div v-else>
        <button v-if="isInUserLibrary" class="btn book-action remove-button">
          <i class="bi bi-check" />
        </button>
        <button
          v-else
          class="btn book-action add-button"
          title="Ajouter à mes livres"
          @click.stop="addBook(book)"
        >
          <i class="bi bi-plus" />
        </button>
      </div>
    </template>
  </book-cover>
</template>

<script setup>
import BookCover from "@/components/book/BookCover.vue";
import LibraryService from "@/services/LibraryService";
import { useAuthentication } from "@/use/useAuthentication";
import { notifyError, notifySuccess } from "@/utils/notification";
import { ref, watch } from "vue";

const { isConnected } = useAuthentication();
const props = defineProps({
  book: {
    type: Object,
    required: true,
  },
});

/**
 * Liste des livres de l'utilisateur
 */
const isLoading = ref(false);
const isInUserLibrary = ref(false);
const isBookInUserLibrary = async () => {
  isLoading.value = true;
  if (props.book.isUserBook()) {
    isInUserLibrary.value = true;
  } else {
    const response = await LibraryService.getMyBooks({ isbn: props.book.isbn[0].identifier });
    if (response.data.length) {
      isInUserLibrary.value = true;
    }
  }
  isLoading.value = false;
};
watch(() => props.book, () => {
  if (isConnected.value) {
    isBookInUserLibrary();
  }
}, {
  deep: true,
  immediate: true,
});

/**
 * Gestion de l'ajout de livres
 */
const addBook = async (book) => {
  isLoading.value = true;
  try {
    await LibraryService.addBook(book);
    notifySuccess("Le livre a bien été ajouté à la bibliothèque.");
    isInUserLibrary.value = true;
  } catch (error) {
    notifyError("Une erreur s'est produite lors de l'ajout du livre.");
  } finally {
    isLoading.value = false;
  }
};
</script>

<style scoped>
.book-action {
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-button {
  color: #000;
  background-color: #ffffff70;
}
.add-button:hover {
  background-color: #ffffff;
}
.remove-button {
  color: #fff;
  background-color: #19875485;
}
.remove-button:hover {
  background-color: var(--bs-success);
}
.loading {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 2px;
  width: 25px;
  height: 25px;
}
</style>