<template>
  <div>
    <ul class="nav nav-underline login-actions">
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: !signup }"
          aria-current="page"
          href="#"
          @click="signup = false"
        >Connectez-vous</a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: signup }"
          href="#"
          @click="signup = true"
        >Créer un compte</a>
      </li>
    </ul>

    <form @submit.prevent="authenticate">
      <div class="form-floating mt-5 mb-3">
        <input
          id="email"
          v-model="email"
          type="email"
          required
          class="form-control"
          placeholder="name@example.com"
        >
        <label for="email">E-mail</label>
      </div>
      <div class="form-floating">
        <input
          id="password"
          v-model="password"
          type="password"
          required
          class="form-control"
          placeholder="Password"
        >
        <label for="password">Mot de passe</label>
      </div>

      <footer>
        <button type="submit" class="btn">
          {{ submitText }}
        </button>
      </footer>
    </form>

    <img class="login-image" src="@/assets/images/reading-book.png" alt="Authentication">
  </div>
</template>

<script setup>
import router from "@/router";
import UserService from "@/services/UserService";
import { useAuthentication } from "@/use/useAuthentication";
import { notifyError, notifySuccess } from "@/utils/notification";
import { computed, ref } from "vue";

const email = ref("");
const password = ref("");
const signup = ref(false);

const submitText = computed(() => signup.value ? "Créer un compte" : "Se connecter");
const authenticate = () => signup.value ? createUserAccount() : login();
const { signIn } = useAuthentication();

/**
 * Connexion
 */
const login = async () => {
  try {
    await signIn(email.value, password.value);
    notifySuccess("Vous êtes connecté.");
    router.push("/stats");
  } catch (error) {
    notifyError("Une erreur s'est produite lors de l'authentification.");
  }
};

/**
 * Création de compte
 */
const createUserAccount = async () => {
  try {
    await UserService.createUserAccount(email.value, password.value);
    notifySuccess("Votre compte a bien été créé.");
    login();
  } catch (error) {
    notifyError("Une erreur s'est produite lors de la création de votre compte.");
  }
};
</script>

<style scoped>
  .login-actions {
      display: flex;
      align-items: center;
      justify-content: center;
  }
  .nav-link {
    color: var(--bs-gray);
  }
  .nav-link.active {
    color: var(--accent-color-darker);
  }

  form {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
  footer {
    margin-top: 1rem;
  }
  footer button {
    width: 100%;
  }
  .login-image {
    position: absolute;
    width: 500px;
    bottom: 5vh;
    left: 5vw;
  }
</style>