import http from "../http-common";

class UserService {
  createUserAccount (login, password) {
    return http.post("/users", {
      login,
      password,
    });
  }
}

export default new UserService();