import axios from "axios";
import router from "./router";

const noTokenPaths = ["/login", "/books"];

const instance = axios.create({
  baseURL: (process.env.VUE_APP_BASE_URL || "http://localhost:8081") + "/api/v1",
  headers: { "Content-type": "application/json" },
});

instance.interceptors.request.use(function (config) {
  if (noTokenPaths.includes(config.url)) {
    return config;
  }

  const jwtToken = localStorage.getItem("jwtToken");
  if (jwtToken) {
    config.headers.Authorization = `Bearer ${jwtToken}`;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status === 403) {
    router.push("/login");
  }
  return Promise.reject(error);
});

export default instance;