<template>
  <section>
    <button v-if="haveMoreResults" @click="goToNextPage">
      Afficher plus
    </button>
  </section>
</template>

<script setup>
const props = defineProps({
  firstResultIndex: {
    type: Number,
    required: true,
  },
  haveMoreResults: {
    type: Boolean,
    required: true,
  },
});
const emit = defineEmits(["update:firstResultIndex"]);
const goToNextPage = () => {
  emit("update:firstResultIndex", props.firstResultIndex + 10);
};
</script>

<style scoped>
section {
    display: flex;
    justify-content: center;
    margin-bottom: 2vh;
}
button {
    border: 0;
    padding: 1vw 3vw;
    border-radius: 5px;
    color: #333;
    transition: background-color .3s ease-out;
}
button:hover {
    background-color: #333;
    color: #FFF;
}
button:active {
    background-color: #f7f73c;
}
</style>