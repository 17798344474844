<template>
  <div class="stat-display">
    <div class="stat-options">
      <label for="mode-by-books">Par livres</label>
      <input
        id="mode-by-books"
        v-model="mode"
        type="radio"
        name="mode"
        value="byBooks"
      >
      <label for="mode-by-pages">Par pages</label>
      <input
        id="mode-by-pages"
        v-model="mode"
        type="radio"
        name="mode"
        value="byPages"
      >
    </div>

    <bar-stat :chart-data="chartData" :chart-options="chartOptions" />
  </div>
</template>

<script setup>
import BarStat from "@/components/stat/BarStat.vue";
import { computed, ref } from "vue";

const props = defineProps({
  numberOfBooks: {
    type: Object,
    required: true,
  },
  numberOfPages: {
    type: Object,
    required: true,
  },
});

const chartOptions = { scales: { y: { beginAtZero: true } } };
const rootStyle = window.getComputedStyle(document.documentElement);
const mainColor = rootStyle.getPropertyValue("--main-color").trim();
const accentColorDarker = rootStyle.getPropertyValue("--accent-color-darker").trim();

const mode = ref("byBooks");
const values = computed(() => {
  if(mode.value === "byBooks") {
    return props.numberOfBooks;
  }
  return props.numberOfPages;
});

const chartData = computed(() => {
  return {
    labels: Object.keys(values.value).map(monthInEnglish => monthMap[monthInEnglish.toUpperCase()]),
    datasets: [
      {
        label: `Nombre de ${mode.value === "byBooks" ? "livres lus" : "pages lues"} par mois`,
        data: Object.values(values.value),
        backgroundColor: mainColor,
        borderColor: accentColorDarker,
        borderWidth: 1,
      },
    ],
  };
});

const monthMap = {
  JANUARY: "Janvier",
  FEBRUARY: "Février",
  MARCH: "Mars",
  APRIL: "Avril",
  MAY: "Mai",
  JUNE: "Juin",
  JULY: "Juillet",
  AUGUST: "Août",
  SEPTEMBER: "Septembre",
  OCTOBER: "Octobre",
  NOVEMBER: "Novembre",
  DECEMBER: "Décembre",
};
</script>

<style scoped>
.stat-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--main-color-lighter);
  box-shadow: 0 0 10px #dfd7d7;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 2vh 1vw;
}

.stat-options {
  width: 100%;
  cursor: pointer;
  display: flex;
  column-gap: 10px;
}

</style>